/* DO NOT EDIT. THIS FILE IS AUTOGENERATED. RUN `yarn build:i18n` instead. */

export const locale = {
    "alphabet": "abcćdefghijklłmnoprsśtuwzźż",
    "article": {
        "source-article-link": "Artykuł ten posiada artykuł źródłowy, który można znaleźć tutaj:",
        "sources-title": "Źródła",
        "references-title": "Piśmiennictwo",
        "authors-title": "Opracowanie",
        "former-authors": "Poprzedni autorzy",
        "international-cooperation-title": "Poprzedni autorzy",
        "international-cooperation-content": "Oryginalna wersja tego artykułu opiera się na odpowiednim artykule w norweskim internetowym podręczniku medycyny rodzinnej Norsk Elektronisk Legehåndbok"
    },
    "audio-fallback": "Twoja przeglądarka nie obsługuje odtwarzania dźwięku",
    "back": "Wróć",
    "block-content": {
        "unknown-error": "Wystąpił błąd"
    },
    "breadcrumb": {
        "breadcrumbs": "Okruszki",
        "pathnames": {
            "/calculators": "Kalkulator",
            "/concurrent-logins": "Jednoczesne logowanie",
            "/cookie-declaration": "Cisteczka",
            "/glossary": "Słownik",
            "/logged-out": "Wyloguj się",
            "/search": "Szukaj",
            "/travel-vaccinations": "Szczepienia",
            "/important-updates": "Aktualności"
        }
    },
    "choose-a-country": "Kraj",
    "click-here": "Wybierz",
    "close-gallery": "Zamknij galerię",
    "close-snackbar": "Zamknij",
    "close-popover": "Zamknij",
    "content-menu": "Menu",
    "copy": {
        "false": "Kopiuj link",
        "true": "Skopiowano link"
    },
    "course": {
        "title": "Tytuł"
    },
    "404": {
        "title": "Strona której szukasz jest w trakcie opracowywania lub nie istnieje.​",
        "subtitle": "Systematycznie poszerzamy i aktualizujemy Medibas.",
        "description": "Rekomendacja dalszych działań:<ul><li>Przejdź do strony głównej Medibas​</li><li>Wyślij zapytanie do redakcji na adres redakcja@medibas.pl​</li><li>Skorzystaj z wyszukiwarki i ponownie przeszukaj serwis:</li></ul>"
    },
    "example": "Przykład",
    "external-link": {
        "checkbox-label": "Nie pokazuj więcej tej informacji",
        "close": "Zamknij",
        "title": "Link zewnętrzny"
    },
    "fallback-template-name": "Artykuł",
    "favorite": {
        "favorites": "Ulubione",
        "latest": "Ostatni {{count}}",
        "add-to-favorites": "Dodaj do Ulubionych",
        "remove-from-favorites": "Usuń z Ulubionych",
        "manage-all": "Wyświetl/edytuj wszystko",
        "none": "Nie ma nic w Ulubionych",
        "sort": {
            "abc": "Alfabetycznie",
            "options": "Sortuj Ulubione",
            "time": "Data",
            "template": "Kategoria"
        },
        "sort-by": "Sortuj:"
    },
    "feedback": {
        "body": "Treść wiadomości",
        "cancellation": "Anulowanie subskrypcji",
        "editorial-feedback": "Kontakt z Redakcją",
        "email": "E-mail",
        "error-sending-ticket": "Wystąpił błąd podczas wysyłania żądania. Skontaktuj się z nami pod adresem support@medibas.pl",
        "forgotten-password": "Reset hasła",
        "general": "Ogólne",
        "groupId": "Wybierz temat",
        "invoice": "Rozliczenia",
        "name": "Nazwisko",
        "order": "Subskrypcja",
        "price-quote": "Zapytanie o ofertę",
        "send": "Wyślij",
        "subject": "Temat",
        "ticket-sent": "Zapytanie zostało wysłane",
        "title": "Twoja wiadomość"
    },
    "general-error": "Ups!",
    "glossary": {
        "header": "Coś poszło nie tak.",
        "helper": "Słowniczek: Wprowadź co najmniej dwie litery lub wybierz literę",
        "search-label": "Wyszukaj w Słowniczku",
        "title": "Słowniczek (A-Z)"
    },
    "go-to-homepage": "Wróć na Stronę Główną",
    "header": {
        "feedback": "Napisz wiadomość",
        "logo-alt": "Medibas",
        "notifications": "Powiadomienia",
        "profile": "Twój Profil",
        "show-notifications": "Wyświetl {{amount}} powiadomienia"
    },
    "home": "Strona Główna",
    "important-updates": {
        "important-update": "Aktualności",
        "important-updates": "Aktualności"
    },
    "last-approved-by": "Ostatnia autoryzacja",
    "last-revised": "Ostatnia weryfikacja",
    "last-updated": "Ostatnia aktualizacja",
    "revision-interval": "Kolejna aktualizacja",
    "layouts": {
        "article-skeleton": "Ładowanie strony",
        "error": "Błąd",
        "unauthorized": "Brak dostępu"
    },
    "loading-please-wait": "Ładowanie strony, proszę czekać",
    "login": "Zaloguj się",
    "login-again": "Zaloguj ponownie",
    "logout": "Wyloguj się",
    "megamenu": {
        "no-topics-found": "Brak wyników wyszukiwania",
        "close": "Zamknij Menu Główne",
        "open": "Otwórz Menu Główne"
    },
    "missing-description": "Brak opisu",
    "new-abbreviation": "Nowe",
    "new-articles": {
        "new-and-updated-articles": "Nowe i zaktualizowane",
        "copy-as-html": {
            "false": "Skopiuj link HTML",
            "true": "Skopiowano",
            "tooltip": "Skopiuj cały artykuł jako HTML"
        },
        "professional": "Dla lekarza",
        "public": "Artykuł otwarty",
        "search-in-articles": "Wyszukaj artykuł",
        "from-date": "Od daty",
        "type-of-articles": "Rodzaj Artykułu"
    },
    "next": "Następny",
    "no-country": "Nie odnaleziono kraju",
    "notifications": {
        "no-new-notifications": "Brak nowych powiadomień",
        "notification-was-removed": "Powiadomienie zostało usunięte",
        "remove-notification": "Usuń powiadomienie",
        "title": "Powiadomienie"
    },
    "on-print": {
        "copy-right": "Prawa autorskie © $year Bonnier Healthcare Polska.",
        "disclaimer": "To jest transkrypcja artykułu z Medibas. Nasze ogólne warunki użytkowania można znaleźć na naszej stronie internetowej tutaj: https://medibas.pl/zastrzezenie",
        "print-from-product": "Wydruk z",
        "printed-by": "wydrukowane przez",
        "video-placeholder": "Wersja online tego artykułu zawiera wideo. Użyj kodu QR lub adresu URL powyżej, aby wyświetlić wideo w wersji internetowej."
    },
    "open-section": {
        "false": "Otwórz sekcję",
        "true": "Zamknij sekcję"
    },
    "outdated": {
        "bg-alt": "Logotypy aktualnych przeglądarek",
        "description": "Ze względów bezpieczeństwa prosimy o wykonanie aktualizacji",
        "link": "Zobacz tutaj dostępne opcje",
        "title": "To jest przestarzała przeglądarka"
    },
    "paywall": {
        "toolbox-subscriber": {
            "title": "Niedostępna treść",
            "text": "Nie masz dostępu? Skontaktuj się, aby uzyskać dostęp do materiałów szkoleniowych."
        },
        "article-public-open-not-logged-in": {
            "title": "Pracujesz w ochronie zdrowia?",
            "text": "Ten artykuł jest materiałem edukacyjnym dla pacjentów. Zaloguj się lub zarejestruj i wykup dostęp do artykułów medycznych dla pracowników ochrony zdrowia."
        },
        "article-public-open-no-sub": {
            "title": "Bądź na bieżąco",
            "text": "Wykup dostęp do artykułów medycznych dla pracowników ochrony zdrowia."
        },
        "article-public-not-logged-in": {
            "title": "Pracujesz w ochronie zdrowia?",
            "text": "Ten artykuł jest materiałem edukacyjnym dla pacjentów. Zaloguj się lub zarejestruj i wykup dostęp do artykułów medycznych dla pracowników ochrony zdrowia."
        },
        "article-public-no-sub": {
            "title": "Bądź na bieżąco",
            "text": "Wykup dostęp do artykułów medycznych dla pracowników ochrony zdrowia."
        },
        "not-logged-in": {
            "title": "Bądź na bieżąco",
            "text": "Zaloguj się. Nie masz jeszcze konta, zarejestruj się i wykup dostęp do Medibas."
        },
        "no-sub": {
            "title": "Bądź na bieżąco",
            "text": "Wykup dostęp aby kontynuować."
        },
        "close": "Zamknij",
        "order": "Wykup dostęp"
    },
    "print": "Drukuj",
    "product-name": "Medibas",
    "profile": {
        "logged-in-as-organisation": "Jesteś zalogowany jako użytkownik organizacji:",
        "login-with-personal-account": "Jeśli posiadasz konto osobiste, zaloguj się tutaj",
        "choose-region": "Wybierz Region",
        "close": "Zamknij Profil",
        "color-mode": {
            "auto": "Przełącz na tryb automatyczny",
            "dark": "Przełącz na ciemny",
            "light": "Przełącz na jasny",
            "period": {
                "auto": "Zaciemnienie między {{from}} a {{to}}",
                "dark": "Zestaw motywu ciemnego",
                "light": "Zestaw motywu jasnego"
            }
        },
        "contact": "Kontakt",
        "dictionary": {
            "toggle-mark-words": "Pokaż objaśnienia słów w tekście",
            "description": "Wyjaśnienie wybranego słowa"
        },
        "edit": "Edytuj Profil",
        "favorites": "Twoje Ulubione",
        "font-size": "Rozmiar czcionki",
        "logged-out": {
            "login-button": "Zaloguj się",
            "login-text": ["Nie jesteś zalogowany.", ""],
            "login-title": "",
            "request-licenses": "Wniosek o dodatkowe licencje",
            "send-request": "Wyślij wniosek",
            "sending": "Wysyła...",
            "sending-failed": "Wysyłanie e-maila nie powiodło się",
            "example-email": "przyklad@przyklad.pl",
            "type-email": "Wpisz swój adres e-mail",
            "invalid-email": "Nieprawidłowy adres e-mail",
            "subscribe-button": "Zamów subskrybcję",
            "subscribe-text": "Nie zasubskrybowałeś jeszcze Medibas?",
            "request-subject": "Wnioski o licencje",
            "requester-text": "Następujący adres e-mail żąda wielu licencji w Medibas:"
        },
        "notifications": "Powiadomienia",
        "title": "Profil",
        "verified": "Użytkownik zweryfikowany",
        "settings": "Ustawienia"
    },
    "published": "Opublikowany",
    "qr": {
        "body": "Użyj kodu QR lub adresu strony internetowej, aby przejść do wersji online artykułu.",
        "header": "Wersja online artykułu:"
    },
    "realtime-guideline": {
        "footer-tooltip": ""
    },
    "regional-content": {
        "default": "(Standard)",
        "no-content-for-region": "Ten artykuł nie zawiera treści regionalne dla następującego regionu: {{region}}. Wybierz inny region.",
        "no-region": "Brak regionu",
        "no-region-selected": "Nie wybrano regionu. Wybierz region, aby wyświetlić treści regionalne",
        "set-as-default": "Zapisz domyślnie"
    },
    "release-notes": "Informacje o wydaniu",
    "reviewed": "Aktualizowano w ciągu ostatnich 90 dni",
    "reviewed-and-new": "Zaktualizowane i nowe artykuły",
    "revised-abbreviation": "ACT",
    "saved": "Zapisane",
    "search": {
        "all-hits": "Wszystkie wyniki wyszukiwania",
        "arrow-directions": {
            "arrow-up": "STRZAŁKĘ W GÓRĘ ",
            "down": "W DÓŁ",
            "press": "Naciśnij ",
            "and": "i ",
            "to-navigate": ", ​​aby poruszać się po liście"
        },
        "clear-search": "Wyczyść pole wyszukiwania",
        "default-tab-label": "Wszystkie wyniki",
        "error": "Wyszukiwanie nie powiodło się. Rozpocznij nowe wyszukiwanie. Jeśli problem powtórzy się, skontaktuj się z nami.",
        "filter-title": "Zoptymalizuj wyszukiwanie",
        "found-x-hits-for-query": "Wyniki wyszukiwania dla {{query}} {{type}}",
        "history": "Historia wyszukiwania",
        "improve-search": "Słowa kluczowe",
        "layout-title": "Szukaj",
        "new": "Nowy",
        "no-hits": "Twoje wyszukiwanie nie przyniosło żadnych wyników. Zmodyfikuj wyszukiwane hasło i spróbuj ponownie.",
        "no-hits-tips": [
            "Wskazówki dot. wyszukiwania",
            "Sprawdź pisownię wyszukiwanego hasła",
            "Wypróbuj synonimy lub alternatywne słowa",
            "Spróbuj wprowadzić bardziej ogólny termin"
        ],
        "no-hits-for-term": "Brak dopasowań dla słowa kluczowego {{query}}",
        "revised": "Act",
        "search": "Szukaj",
        "search-placeholder": "Wyszukuj po chorobach, objawach i innych słowach kluczowych",
        "searching": "Wyszukiwanie",
        "searching-please-wait": "Wyszukiwanie, proszę czekać...",
        "show-more-hits": "Pokaż więcej wyników",
        "too-long": "Wyniki wyszukiwania dla '{{query}}'. Szukana fraza jest ograniczona do maksymalnie 10 słów.",
        "fuzzy": "<strong>Żadne artykuły nie pasują do Twojego wyszukiwania '{{query}}'. </strong><br />Poniższe wyniki wyszukiwania opierają się na wyszukiwaniu słów podobnych do wprowadzonych przez Ciebie."
    },
    "share-article": "Udostępnij kod QR",
    "show-more": "Zobacz więcej",
    "show-navigation": "Pokaż nawigację",
    "show-reference": "Pokaż odniesienie",
    "show-references": "Pokaż odniesienia",
    "show-tools": "Pokaż narzędzia",
    "site-name": "Medibas",
    "skip-to-main-content": "Przejdż do głównej zawartości",
    "table": {
        "table": "Tabela",
        "open-table": "Otwórz tabele"
    },
    "tools": {
        "calculators": {
            "action": "Zobacz wszystko",
            "title": "Wyniki, testy, kwestionariusze"
        },
        "checklists": {
            "action": "",
            "title": "Listy kontrolne"
        },
        "courses": {
            "action": "Zobacz wszystkie kursy",
            "title": "Kurs"
        },
        "show-more": "Pokaż więcej",
        "show-less": "Pokaż mniej",
        "loading": {
            "action": "",
            "content": "Proszę czekać",
            "title": "Ładuje się"
        },
        "patient-information": {
            "action": "Zobacz wszystko",
            "title": "Informacja dla pacjenta"
        },
        "regional-content": {
            "action": "Zobacz treść",
            "content": "W tym artykule opisane procedury regionalne.",
            "title": "Treść regionalna"
        },
        "related-articles": {
            "action": "",
            "title": "Powiązane tematy"
        },
        "title": "Narzędzia"
    },
    "unauthenticated-request": "Musisz być zalogowany",
    "unauthorized-request": "Brak dostępu",
    "profession-popup": {
        "message": "Nie podałeś zawodu/specjalizacji. Wybierz poniżej odpowiedni.",
        "profession-input-label": "Zawód",
        "speciality-input-label": "Specjalizacja",
        "submit-button": "Aktualizuj"
    },
    "meta": {
        "og-title-suffix": ""
    },
    "user-details": {
        "confirm": "",
        "success": "",
        "fill-and-confirm": "",
        "name": "",
        "email": "",
        "address": "",
        "zip": "",
        "city": "",
        "specialization-1": "",
        "specialization-2": "",
        "study-program": "",
        "profession": "",
        "profession-category": "",
        "work-location": "",
        "study-location": "",
        "hpr-number": "",
        "remind-me-later": "",
        "remind-me-later-text": "",
        "confirm-button": ""
    }
} as const